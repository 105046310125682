import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable()

export class ApiService {
  constructor(private http: HttpClient) {}

  public getMembershipCost() {
    return this.http.get(environment.api + 'get-engine-costs');
  }

  // get policy by uuid
  public getCustomerByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-customer-by-uuid/' + uuid);
  }

  // get membership by uuid
  public getMembershipByID(id) {
    return this
      .http
      .get(environment.api + 'get-membership-by-id/' + id);
  }

  // get vet by uuid
  public getVetByID(id) {
    return this
      .http
      .get(environment.api + 'get-vet-by-id/' + id);
  }

  // get trailer by uuid
  public getTrailerByID(id) {
    return this
      .http
      .get(environment.api + 'get-trailer-by-id/' + id);
  }

  // get policy by uuid
  public getPolicyByUUID(uuid) {
    return this
      .http
      .get(environment.api + 'get-policy-by-uuid/' + uuid);
  }

  // get policy by uuid
  public getPolicyByCustomerID(id) {
    return this
      .http
      .get(environment.api + 'get-policy-by-customer-id/' + id);
  }

  public getMTACost(data) {
    return this.http.post<any>(environment.api + 'mta-policy-charge', data);
  }

  public saveMTA(data) {
    return this.http.post<any>(environment.api + 'mta-policy', data);
  }

  public upgradeVetFee(data) {
    return this.http.post<any>(environment.api + 'upgrade-vet-fee-cover', data);
  }

  public upgradeTrailer(data) {
    return this.http.post<any>(environment.api + 'upgrade-trailer-cover', data);
  }

  public getTrailerMTACharges(data) {
    return this.http.post<any>(environment.api + 'get-trailer-change-charges', data);
  }

  public addTrailer(data) {
    return this.http.post<any>(environment.api + 'purchase-new-trailer-cover', data);
  }

  public saveTrailerMTA(data) {
    return this.http.post<any>(environment.api + 'mta-trailer-cover', data);
  }

  public upgradeMembership(data) {
    return this.http.post<any>(environment.api + 'upgrade-membership', data);
  }

  public saveMembership(data) {
    return this.http.post<any>(environment.api + 'purchase-new-membership', data);
  }

  public saveFamilyMembers(data) {
    return this.http.post<any>(environment.api + 'update-family-members', data);
  }

  public updateAutoRenew(data) {
    return this.http.post<any>(environment.api + 'set-auto-renew', data);
  }

  public updateMarketingPrefrence(data) {
    return this.http.post<any>(environment.api + 'set-marketing-preference', data);
  }

  public updateDD(data) {
    return this.http.post<any>(environment.api + 'update-direct-debit-customer', data);
  }

  public updateDDToSwitch(data) {
    return this.http.post<any>(environment.api + 'shift-customer-to-direct-debit', data);
  }

  public askDirectDebitSwitch(data) {
    return this.http.post<any>(environment.api + 'direct-debit-shift-actioned', data);
  }

  public verifyMigration(data) {
    return this.http.post<any>(environment.api + 'verify-migration', data);
  }

  public purchasePolicyWithDD(data) {
    return this.http.post<any>(environment.api + 'purchase_public_policy_direct_debit', data);
  }

  public updateRePurchase(data) {
    return this.http.post<any>(environment.api + 're-purchase-policy', data);
  }

  public updatePlanOnRenew(data) {
    return this.http.post<any>(environment.api + 'set-auto-renew-plan', data);
  }

  public onGetHorseChangeCharges(data) {
    return this.http.post<any>(environment.api + 'get-horse-change-charges', data);
  }

  public saveHorseMTA(data) {
    return this.http.post<any>(environment.api + 'update-customer-horses', data);
  }

  // get Documents by uuid
  public getDocumentContent(id) {
    return this.http.get<Blob>(environment.api + 'documents/download/' + id, {
      responseType: 'blob' as 'json',
    });
  }

  public download(content, filename, contentType) {
    if (!contentType) {
      contentType = 'application/octet-stream';
    }
    const a = document.createElement('a');
    const blob = new Blob([content], { 'type': contentType });
    a.href = window.URL.createObjectURL(blob);
    a.download = filename;
    a.click();
  }

  // Save Document
  public saveDocument(data) {
    return this.http.post<any>(environment.api + 'documents/save', data);
  }

  // External urls
  // Get address by postcode
  public getAddress(postcode) {
    return this
      .http
      .get('https://api.getAddress.io/find/' + postcode + '?api-key=' + environment.getAddApiKey + '&expand=true ');
  }

  // get Documents by uuid
  public getMaintenanceStatus() {
    return this.http.get(environment.api + 'maintenance/status');
  }

  public epoqRegisterUser(data) {
    return this.http.post<any>(environment.api + 'epoq-register-user', data);
  }
}
