import { AuthenticationComponent } from './authentication/authentication.component';
import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { CustomerComponent } from './layouts/customer/customer.component';
import { AuthGuard } from './services/auth.guard';

export const Approutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      { path: '', redirectTo: '/customer/dashboard', pathMatch: 'full' },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
      },
      {
        path: 'pages',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
      }
    ]
  },
  {
    path: 'customer',
    component: CustomerComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
      },
    ]
  },
  {
    path: 'authentication/:id/:tab',
    component: AuthenticationComponent,
  },
  {
    path: 'authentication/:id',
    component: AuthenticationComponent,
  },
  {
    path: '**',
    redirectTo: '/customer/dashboard'
  }
];
