import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

declare const reviewsBadgeModern: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit, AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router, public apiService: ApiService, config: NgbPopoverConfig) {
    config.container = 'body';
    config.placement = 'top';
    config.triggers = 'click';
  }

  public innerWidth: number = 0;
  public defaultSidebar: string = '';
  public showMobileMenu = false;
  public expandLogo = false;
  public sidebartype = 'full';

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    this.defaultSidebar = this.sidebartype;
    this.handleSidebar();

    if (environment.production) {
      reviewsBadgeModern('badge-230', {
        store: 'harry-hall',
        primaryClr: '#202440',
        starsClr: '#0076a5'
      });
    }
  }

  ngAfterViewInit() {}

  @HostListener('window:resize', ['$event'])

  onResize() {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1170) {
      this.sidebartype = 'mini-sidebar';
    } else {
      this.sidebartype = this.defaultSidebar;
    }
  }

  toggleSidebarType() {
    switch (this.sidebartype) {
      case 'full':
        this.sidebartype = 'mini-sidebar';
        break;

      case 'mini-sidebar':
        this.sidebartype = 'full';
        break;

      default:
    }
  }
}
