import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private registerUrl = `${environment.api}auth/register`;
  private loginUrl = `${environment.api}login`;
  private logoutUrl = `${environment.api}auth/logout`;
  private updateUrl = `${environment.api}customer`;
  private savePasswordeUrl = `${environment.api}change-password`;
  private forgotUrl = `${environment.api}forget-password`;

  constructor(private http: HttpClient) {}

  // User function
  registerUser(user) {
    return this.http.post<any>(this.registerUrl, user);
  }

  loginUser(user) {
    return this.http.post<any>(this.loginUrl, user);
  }

  logoutUser(user) {
    return this.http.post<any>(this.logoutUrl, user);
  }

  forgot(user) {
    return this.http.post<any>(this.forgotUrl, user);
  }

  getUser() {
    const user = localStorage.getItem('user') as string;
    return JSON.parse(user);
  }

  updateUser(user) {
    return this.http.post<any>(this.updateUrl, user);
  }

  savePassword(user) {
    return this.http.post<any>(this.savePasswordeUrl, user);
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  isActive() {
    const usr = localStorage.getItem('user');
    if (this.loggedIn() && usr) {
      const user = JSON.parse(usr);
      if (user.status === 1) {
        return true;
      }
    }
    return false;
  }

  refreshUserInfo() {
    return this
      .http
      .get(environment.api + 'user-profile');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  removeToken() {
    localStorage.removeItem('user');
    return localStorage.removeItem('token');
  }
}
