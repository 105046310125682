<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="container-fluid p-0">
  <div id="main-menu">
    <div class="container">
      <div id="desktopMenu">
        <div class="row no-gutters">
          <div class="col-md-3 bigName">
            HARRY HALL
          </div>
          <div class="col-md-9 main-menu text-right">
            <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
          </div>
        </div>
      </div>
      <div id="mobileToggle">
        <div class="row no-gutters">
          <div class="col-6 bigName">
            HARRY HALL
          </div>
          <div class="col-6 main-menu text-right">
            <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="main" class="login-bg" style="min-height: 402.5px;">
    <div class="container">
      <div class="login-modal">
        <br style="clear: both;">
        <h2>{{pageTitle}}</h2>
        <br style="clear: both;">
      </div>
    </div>
  </div>

  <div id="footer">
    <div class="container">
      <div class="row no-gutters">
        <div id="reviewsio" class="col-md-4 text-center">
          <div id="badge-230" style="max-width:230px;"></div>
        </div>
        <div class="col-md-8">
          Call us on <a href="tel:01274 711011">01274 711011</a>
          <br /><br />
          It is important that you respond accurately, if you don't it may affect the amount that you pay and the claims
          that we pay. If incorrect responses have been provided we may reduce or not make a claim payment or cancel
          your policy and we may not refund your premiums. Your responses must be correct for each person, animal or
          vehicle you want to insure.
          <br /><br />
          *One Club Membership shopping vouchers for HarryHall.com are subject to terms and availability and will be
          delivered in a confirmation email to the email address provided. Full terms for the voucher will be provided
          in the email.
          <br /><br />
          <a href="https://harryhall.com/terms-and-conditions" target="_blank">Terms & Conditions</a>&nbsp;&nbsp;&nbsp;
          <a href="https://support.harryhall.com/portal/kb/articles/privacy-policy-21-11-2019" target="_blank">Privacy Policy</a>&nbsp;&nbsp;&nbsp;
          <a href="https://harryhall.com/help-contact-us" target="_blank">Contact Us</a>
          <br /><br />
          &copy; Copyright Harry Hall International Limited.
          <br /><br />
          Equario Insurance (Guernsey) Limited, is registered in Guernsey under Registration Number 69867 and is
          regulated by the Guernsey Financial Services Commission. Equario Insurance (Guernsey) Limited’s registered
          office is Level 5, Mill Court, La Charroterie, St Peter Port, Guernsey GY1 1EJ.
          <br /><br />
          <span>Harry Hall International Limited is authorised and regulated by the Financial Conduct Authority (FCA) our firm reference number is 968047. You may check this on the Financial Services Register by <a href="https://register.fca.org.uk/s/">visiting the FCA website click here</a></span>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- End footer -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
</div>