import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss']
})

export class AuthenticationComponent {
  pageTitle = 'Please wait...';
  token = '';
  tab = 'summary';

  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {
    this.token = this.route.snapshot.paramMap.get('id') as string;
    this.tab = this.route.snapshot.paramMap.get('tab') as string;

    switch (this.tab) {
      case 'summary':
        localStorage.setItem("activeTab", 'summary');
        break;
      case 'products':
        localStorage.setItem("activeTab", 'products');
        break;
      case 'horses':
        localStorage.setItem("activeTab", 'horses');
        break;
      case 'other':
        localStorage.setItem("activeTab", 'other');
        break;
      case 'documents':
        localStorage.setItem("activeTab", 'documents');
        break;
      case 'payments':
        localStorage.setItem("activeTab", 'payments');
        break;
      case 'transaction':
        localStorage.setItem("activeTab", 'transaction');
        break;
      default:
        localStorage.setItem("activeTab", 'summary');
        break;
    }

    if (this.token) {
      localStorage.setItem('token', 'bearer ' + this.token);
      localStorage.setItem('appLogin', 'true');
      this.authService.refreshUserInfo()
        .subscribe(
          (res: any) => {
            localStorage.setItem('user', JSON.stringify(res));
            this.router.navigate(['/customer/dashboard']);
          }
        );
    }
  }
}
