import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-customer-navigation',
  templateUrl: './navigation.component.html'
})

export class CustomerNavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  public userName = '';

  constructor(private modalService: NgbModal, private authService: AuthService) {
    const user = this.authService.getUser();
    this.userName = user.name;
  }

  openLogin(modal) {
    this.modalService.open(modal, { windowClass: 'modal-default', size: 'md', centered: true });
  }

  onLogout() {
    this.authService.removeToken();
    window.location.reload();
  }

  isAppLogin() {
    return localStorage.getItem('appLogin');
  }
}
