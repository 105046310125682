import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoaderService } from '../../services/loader.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})

export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  forgotForm = this.fb.group({
    email: ['', Validators.required],
  });

  forgotPassword = false;
  alertMessage = '';
  userName = '';

  link = environment.link;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private authService: AuthService, private router: Router, private loaderService: LoaderService) {
    const user = this.authService.getUser();
    if (user) {
      this.userName = user.name;
    }
  }

  openLogin(modal) {
    this.modalService.open(modal, { windowClass: 'modal-default', size: 'md', centered: true });
  }

  onLogin(value) {
    if (this.loginForm.invalid) {
      this.loaderService.showToast('Please fill valid value for all required fields', 'danger');
      return;
    }

    this.authService.loginUser(value)
      .subscribe(
        res => {
          if (res.success) {
            localStorage.setItem('token', 'bearer ' + res.access_token);
            localStorage.setItem('user', JSON.stringify(res.user));

            this.modalService.dismissAll();
            this.router.navigate(['/customer/dashboard']);
          } else {
            this.loaderService.showToast(res.message, 'danger');
          }

        },
        err => {
          this.loaderService.showToast(err.message, 'danger');
          // console.log(err);
        },
      );
  }
}
