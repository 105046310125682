<div id="top-menu">
  <div class="container">
    <div class="row no-gutters">
      <div class="col text-right">
        <ul style="display: inline;">
          <li class="top-menu-item" *ngIf="!userName"><a href="/#/pages/login">Login</a></li>
          <li class="top-menu-item" *ngIf="userName">Welcome: <a href="/#/customer/dashboard" >{{userName}}</a></li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div id="main-menu">
  <div class="container">
    <div id="desktopMenu">
      <div class="row no-gutters">
        <div class="col-md-3 col-sm-6 bigName">
         <a href="{{link}}/">HARRY HALL</a>
        </div>
        <div class="col-md-9 main-menu text-right">
          <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
        </div>
      </div>
    </div>
    <div id="mobileToggle">
      <div class="row no-gutters">
        <div class="col-8 bigName">
          HARRY HALL
        </div>
        <div class="col-4 main-menu text-right">
          <span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loginModal let-c="close" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h3 class="modal-title" id="modal-title-info">Member Login</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <form [formGroup]="loginForm" *ngIf="!forgotPassword; else elseForm" role="form">
        <div id="login-form">
          <label>Email address</label><br />
          <input type="text" formControlName="email" class="login-field" /><br />
          <div style="float:left;"><label>Password</label></div>
          <div class="purple-question" style="float:left; margin-left:10px; margin-top:5px;"><a href="javascript:;">?</a></div>
          <br style="clear:both" />
          <input type="password" formControlName="password" class="login-field" /><br />
          <div id="forgotten-password"><a href="javascript:;">Forgotten password?</a></div>
          <br />
          I am not a robot section<br /><br />
          <button type="button" (click)="onLogin(loginForm.value)" class="login-submit">Log in</button><br /><br />
          <br style="clear:both" /><br />
        </div>
      </form>
    </div>
  </div>
</ng-template>
