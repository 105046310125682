<div id="top-menu">
	<div class="container">
		<div class="row no-gutters">
			<div class="col text-right">
				<ul style="display: inline;">
					<li class="top-menu-item">Welcome: <a href="/#/customer/dashboard">{{userName}}</a></li>
					<li *ngIf="!isAppLogin()" class="top-menu-item"><a href="javascript:;" (click)="onLogout()">Logout</a></li>
				</ul>
			</div>
		</div>
	</div>
</div>
<div id="main-menu">
	<div class="container">
		<div id="desktopMenu">
			<div class="row no-gutters">
				<div class="col-md-3 bigName">
					HARRY HALL
				</div>
				<div class="col-md-9 main-menu text-right">
					<span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
				</div>
			</div>
		</div>
		<div id="mobileToggle">
			<div class="row no-gutters">
				<div class="col-8 bigName">
					HARRY HALL
				</div>
				<div class="col-4 main-menu text-right">
					<span class="main-menu-logo"><img alt="Logo" src="assets/images/h-logo.png" /></span>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #loginModal let-c="close" let-d="dismiss">
	<div class="modal-content">
		<div class="modal-header">
			<h3 class="modal-title" id="modal-title-info">Member Login</h3>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
				<span aria-hidden="true">×</span>
			</button>
		</div>
		<div class="modal-body">
			<div id="login-form">
				<label>Email address</label><br />
				<input type="text" name="email" class="login-field" /><br />
				<div style="float:left;"><label>Password</label></div>
				<div class="purple-question" style="float:left; margin-left:10px; margin-top:5px;"><a href="javascript:;">?</a>
				</div>
				<br style="clear:both" />
				<input type="password" name="password" class="login-field" /><br />
				<div id="forgotten-password"><a href="javascript:;">Forgotten password?</a></div>
				<br />
				I am not a robot section<br /><br />
				<input type="submit" value="Log in" class="login-submit" /><br /><br />
				<div class="login-button-half"><span><img src="assets/images/icon-facebook.png"></span>&nbsp;&nbsp;&nbsp;<a href="javascript:;">Facebook Login</a></div>
				<div class="login-button-half" style="margin-right: 0px; margin-left: 2%;"><span><img src="assets/images/icon-googleplus.png"></span>&nbsp;&nbsp;&nbsp;<a href="javascript:;">Google Login</a></div>
				<br style="clear:both" /><br />
				<a href="javascript:;"><img src="assets/images/icon-chat-big.png" /></a>
			</div>
			<div id="retrieve-form">
				<label>Email address</label><br />
				<input type="text" name="email" class="login-field" /><br />
				<div style="float:left;"><label>Password</label></div>
				<div class="purple-question" style="float:left; margin-left:10px; margin-top:5px;"><a href="javascript:;">?</a></div>
				<br style="clear:both" />
				<input type="password" name="password" class="login-field" /><br /><br />
				<input type="submit" value="Retrieve quote" class="login-submit" /><br /><br />
				<br /><br /><br />
			</div>
		</div>
	</div>
</ng-template>